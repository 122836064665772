const investmentManage = {
  state: {
    pageType: 'list',
    beginUpdate: new Date(),
    investmentId: null,
    rentTypeArray: [],
    statusArray: [],
    templateArray: [],
    chkDeviceArray: []
  },
  mutations: {
    set_investment_pageType (state, data) {
      state.pageType = data
    },
    set_investment_update (state, data) {
      state.beginUpdate = data
    },
    set_investment_investmentId (state, data) {
      state.investmentId = data
    },
    set_investment_rentTypeArray (state, data) {
      state.rentTypeArray = data
    },
    set_investment_statusArray (state, data) {
      state.statusArray = data
    },
    set_investment_templateArray (state, data) {
      state.templateArray = data
    },
    set_investment_chkDeviceArray (state, data) {
      state.chkDeviceArray = data
    }
  },
  getters: {

  },
  actions: {

  }
}
export default investmentManage
